<!-- @format -->

<template>
    <client-only
        ><LazyMobileModelDrawer
            :title="'aidFund'"
            :header="false"
            :drawer="drawerDetail"
            :hash="'aidFund'"
            :opened="openedFn"
            :closed="closedFn"
        >
            <template #body>
                <div class="mobile-sign">
                    <div class="mobile-sign-header">
                        <div class="flex justify-start items-center">
                            <BaseIcon
                                @click="closeFn"
                                name="left"
                                style="font-size: 26px"
                                class="font-bold mr-[16px]"
                            />
                            <base-img class="h-[29px] w-[91px] logo" name="logo" type="png" path="images/logo" />
                        </div>
                    </div>

                    <div class="mobile-sign-content">
                        <div class="w-full flex flex-col">
                            <el-divider><span class="font-black">Promoções</span> </el-divider>
                            <div class="flex flex-col gap-[5px]">
                                <div class="w-full relative">
                                    <base-img class="w-full !block" name="box 1" type="png" path="images/vip" />
                                    <div class="absolute top-0 w-full h-full flex flex-col px-[15px] py-[10px]">
                                        <span class="font-black text-[12px] pb-[5px]"
                                            >PERDAS DE ONTEM：R${{
                                                formattedNum(AlmzGetUserStatusResult?.yesterday.lossScore || 0)
                                            }}</span
                                        >
                                        <div class="flex flex-col w-full gap-[2px] flex-1 justify-around">
                                            <span class="font-black text-[13px] text">RESGATE DE ONTEM</span>
                                            <span class="btn text-[10px]">Extra gift:13%</span>
                                            <span class="price"
                                                >R$
                                                {{
                                                    formattedNum(AlmzGetUserStatusResult?.yesterday.almzScore || 0)
                                                }}</span
                                            >
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="flex submit items-center min-h-[40px] justify-center w-full rounded-[4px] text-[13px] font-bold text-color-white"
                                >
                                    Receber
                                </div>

                                <div class="w-full relative">
                                    <base-img class="w-full !block" name="box 2" type="png" path="images/vip" />
                                    <div class="absolute top-0 w-full h-full flex flex-col px-[15px] py-[10px]">
                                        <span class="font-black text-[12px] pb-[5px]"
                                            >PERDAS DE HOJE：R${{
                                                formattedNum(AlmzGetUserStatusResult?.today.lossScore || 0)
                                            }}</span
                                        >
                                        <div class="flex flex-col w-full gap-[2px] flex-1 justify-around">
                                            <span class="font-black text-[13px] text">RESGATE DE HOJE</span>
                                            <span class="btn text-[10px]">Extra gift:13%</span>
                                            <span class="price"
                                                >R$
                                                {{ formattedNum(AlmzGetUserStatusResult?.today.almzScore || 0) }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="flex submit items-center min-h-[40px] justify-center w-full rounded-[4px] text-[13px] font-bold text-color-white"
                                >
                                    Recolha amanhã
                                </div>
                            </div>
                        </div>

                        <div class="w-full flex flex-col mt-[10px]">
                            <div class="title text-color-white">REGRAS DO JOGO</div>
                            <div class="aidFund-list text-[11px] flex flex-col gap-[5px]">
                                <span>1. Resgate= perdas no jogo x taxa de reembolso do nivel nobre.</span>
                                <span
                                    >2. Se o usuário não resgatar o resgate hoje, será considerado como renúncia.</span
                                >
                                <span>3. O horário de liquidação é à meia-noite, horario do Brasil.</span>
                                <span
                                    >4. Se vocé não fizer apostas durante o periodo de reembolso em dinheiro, ou se seus
                                    lucros de ontem cobrirem suas perdas, vocé não receberá o reembolso em
                                    dinheiro.</span
                                >
                                <span
                                    >5. Cada pessoa tem urm limite diario de reembolso em dinheiro de até RS
                                    10.000</span
                                >
                            </div>
                        </div>

                        <div class="w-full flex flex-col mt-[10px] gap-[10px pb-[30px]">
                            <div class="title text-color-white">PRÓXIMO NÍVEL</div>
                            <div class="aidFund-list text-[11px] flex flex-col gap-[5px]">
                                <div
                                    class="text-[12px] flex flex-row justify-between gap-[10px] px-[5px]"
                                    style="color: #3b4358"
                                >
                                    <span
                                        class="flex flex-row justify-center break-all w-[100px]"
                                        v-for="(item, index) in header"
                                        >{{ item.text }}</span
                                    >
                                </div>
                                <div class="flex flex-col mobile-regostro-table-scroll overscroll-none gap-[5px]">
                                    <div
                                        class="text-[13px] mobile-regostro-table-list w-full grid grid-cols-2 gap-[10px]"
                                        v-for="(item, index) in List"
                                    >
                                        <span
                                            class="flex flex-row place-self-start justify-center items-center text-center w-[100px]"
                                            >{{ item.name }}</span
                                        >
                                        <span
                                            class="flex flex-row place-self-end justify-center w-[100px] items-center text-center"
                                            >{{ item.Resgate }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <baseLoading :http="true" v-if="Loading"></baseLoading>
                </div>
            </template>
        </LazyMobileModelDrawer>
    </client-only>
</template>

<script setup lang="ts">
    import {commands} from "~/core/define";
    const drawerDetail = useAidFund();
    const Loading = ref(true);
    const loginInfo = ref<CMD_MB_LogonSuccess>();
    let ws: INetService;
    const header = [
        {text: "Nivel Upgrade", value: 0},
        {text: "Taxa de Resgate", value: 1},
    ];
    const CommonQuery = reactive<CMD_MB_Common_Query>({
        userID: 0,
    });
    const AlmzGetUserStatusResult = ref<CMD_MB_AlmzGetUserStatusResult>();
    const List = [
        {name: "vip0", Resgate: "10%"},
        {name: "vip1", Resgate: "10%"},
        {name: "vip2", Resgate: "10%"},
        {name: "vip3", Resgate: "10%"},
        {name: "vip4", Resgate: "10%"},
        {name: "vip5", Resgate: "10%"},
        {name: "vip6", Resgate: "10%"},
        {name: "vip7", Resgate: "10%"},
        {name: "vip8", Resgate: "10%"},
        {name: "vip9", Resgate: "10%"},
        {name: "vip10", Resgate: "10%"},
        {name: "vip11", Resgate: "10%"},
        {name: "vip12", Resgate: "10%"},
        {name: "vip13", Resgate: "10%"},
        {name: "vip14", Resgate: "10%"},
        {name: "vip15", Resgate: "10%"},
        {name: "vip16", Resgate: "10%"},
        {name: "vip17", Resgate: "10%"},
        {name: "vip18", Resgate: "10%"},
        {name: "vip19", Resgate: "10%"},
        {name: "vip20", Resgate: "10%"},
    ];

    const AlmzGetUserStatusFn = () => {
        return new Promise((resolve, reject) => {
            AlmzGetUserStatus(CommonQuery, "aidFund", (s: INetService, e: INetEventParam) => {
                ws = s;
                if (e.scmd === commands.SUB_MB_AlmzGetUserStatus_RESULT) {
                    AlmzGetUserStatusResult.value = e.data as CMD_MB_AlmzGetUserStatusResult;
                    resolve(e.data);
                }
            });
        });
    };
    const openedFn = () => {
        const loginInfos = getLoginInfo();
        loginInfo.value = loginInfos;
        CommonQuery.userID = loginInfos.userID;
        let result = schedule([AlmzGetUserStatusFn], 2);
        result.then((res) => {
            Loading.value = false;
        });
    };
    const closedFn = () => {
        ws.off("aidFund");
    };
    const closeFn = () => {
        drawerDetail.value = false;
    };
</script>

<style lang="scss" scoped>
    .mobile-sign {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .mobile-sign-header {
            width: 100%;
            height: 60px;
            padding: 0 15px;
            display: flex;
            background-color: #25262b;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.5);
            position: relative;
            z-index: 1;
        }

        .mobile-sign-content {
            width: 100%;

            background-color: #17191f;
            height: calc(100% - 60px - env(safe-area-inset-bottom));
            padding-bottom: env(safe-area-inset-bottom);
            overflow-x: hidden;
            position: relative;
            padding: 0 16px;
            box-sizing: border-box;
            .btn {
                padding: 5px 10px;
                border-radius: 4px;
                background-color: #384b93;
                width: fit-content;
            }
            .price {
                font-family: FZ;
                font-size: 16px;
            }
            .text {
                background: linear-gradient(to right, #fdf12f, #f6971b);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .text-color-white {
            color: #ffffff;
        }

        .submit {
            background-image: linear-gradient(90deg, #629bcc 0%, #3460aa 100%), linear-gradient(#53c16c, #53c16c);
        }

        .title {
            font-weight: bold;
            font-size: 12px;
        }

        .aidFund-list {
            width: 100%;
            border-radius: 4px;
            padding: 10px;
            background-color: #1f1f29;
            color: #9498a1;
        }

        .mobile-regostro-table-scroll {
            height: calc(100% - 30px);
            overflow-x: hidden;
        }
        .mobile-regostro-table-list {
            padding: 10px 5px;
            background-color: #21232b;
            border-radius: 4px;
            color: #3756b4;
            span {
                font-weight: bold;
            }
        }

        ::v-deep(.el-image__inner) {
            height: inherit !important;
        }
        ::v-deep(.el-divider__text) {
            background-color: #17191f;
            padding: 0 8px;
            word-break: break-all;
            width: max-content;
        }
    }
</style>
